<template>
    <main class="swcluster-main" id="swcluster-datasandbox-awsglue">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <div class="header-row">
                    <div class="header-actions">
                        <button class="btn-action btn-action-back" @click="goBack">
                            <i class="icon-arrow"></i>
                            <strong class="text">이전</strong>
                        </button>
                    </div>
                    <CommonBreadcrumb />
                </div>
                <h2 class="page-title">&nbsp;</h2>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-contents -->
            <div class="page-contents">
                <!-- content-section: -->
                <article class="content-section">
                    <header class="section-header header-right">
                        <h3 class="title title-sm">Job {{ jobName }}</h3>
                    </header>
                    <!-- page-tabs -->
                    <nav class="page-tabs">
                        <ul class="tab-list">
                            <li v-for="(t, index) in tabs" class="tab-item" :class="{'is-active': t.key === tab}" :key="index">
                                <a href="javascript:" class="tab" @click="selectTab(t.key)">{{ t.name }}</a>
                            </li>
                        </ul>
                    </nav>
                    <!-- //page-tabs -->
                </article>
                <!-- content-section: -->
                <GlueJobDetail v-if="tab === 'job'" :params="params" />
                <GlueJobScript v-if="tab === 'script'" :params="params" />
                <GlueJobRun v-else-if="tab === 'run'" :job-name="jobName" :params="params" />
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <div class="page-bottom bottom-fixed">
            <div>
                <div class="bottom-row-container bottom-component">
                    <div class="bottom-side">
                        <button class="btn-action btn-primary" @click="execJob"><span class="text">Job 실행</span></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- //page-bottom -->
    </main>
</template>

<script>
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import {getItem, isSuccess, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/common.utils';
import {useRoute, useRouter} from 'vue-router';
import {computed, reactive, ref} from 'vue';
import ApiService from '@/assets/js/api.service';
import GlueJobDetail from '@/components/sandbox/GlueJobDetail';
import GlueJobRun from '@/components/sandbox/GlueJobRun';
import GlueJobScript from '@/components/sandbox/GlueJobScript';
import {hideLoading, showLoading} from '@/assets/js/common.alert';

export default {
    name: 'GlueJob',
    components: {GlueJobScript, GlueJobRun, GlueJobDetail, CommonBreadcrumb},
    setup: function () {
        showLoading();

        const route = useRoute();
        const router = useRouter();

        const tab = ref('job');
        const tabs = [
            {
                name: 'Job Detail',
                key: 'job',
            },
            {
                name: 'Script',
                key: 'script',
            },
            {
                name: 'Runs',
                key: 'run',
            },
        ];

        const jobName = computed(() => route.params.jobName);

        const params = reactive({
            name: '',
            cmdName: '',
            role: '',
            version: '',
            runScript: '',
        });

        const selectTab = key => {
            tab.value = key;
        };

        const goBack = e => {
            if (e.ctrlKey) {
                window.open('/sandbox/glue');
            } else {
                router.push({name: 'Glue'});
            }
        };

        const getJob = () => {
            if (jobName.value) {
                showLoading();
                ApiService.get('/v1/aws/glue/jobs', jobName.value)
                    .then(res => {
                        if (lengthCheck(res)) {
                            setParams(params, getItem(res));
                        }
                        hideLoading();
                    })
                    .catch(e => {
                        console.error(e);
                        hideLoading();
                    });
            }
        };

        const execJob = () => {
            if (confirm(`Glue Job (${jobName.value})을 실행시키겠습니까?`)) {
                showLoading();

                ApiService.post(`/v1/aws/glue/jobs/${jobName.value}/runs`, {})
                    .then(res => {
                        if (isSuccess(res)) {
                            // alert(`Glue Job (${jobName.value})이 실행되었습니다.`);
                            // job run list 초기화
                            tab.value = '';
                            setTimeout(() => {
                                tab.value = 'run';
                            }, 500);
                        } else {
                            alert('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
                        }
                        hideLoading();
                    })
                    .catch(() => {
                        alert('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
                        hideLoading();
                    });
            }
        };

        getJob();

        return {
            tab,
            tabs,
            jobName,
            params,
            selectTab,
            execJob,
            goBack,
            timestampToDateFormat,
        };
    },
};
</script>
