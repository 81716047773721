<template>
    <article class="content-section section-tabcontent">
        <div class="content-text-container">
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Name</h4>
                <div class="item-content">
                    <p class="text">{{ params.name }}</p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">IAM Role</h4>
                <p class="item-description">
                    Role assumed by the job with perimssion to access your data stores. Ensure that this role has permission to your Amazon S3 source , targets,
                    temporary directory, scripts, and anylibraries used by the job.
                </p>
                <div class="item-content">
                    <p class="text">{{ params.role }}</p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Type</h4>
                <p class="item-description">The type of ETL job. This is set automatically based on the types of data sources you have selected.</p>
                <div class="item-content">
                    <p class="text">{{ params.cmdName }}</p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Python version</h4>
                <div class="item-content">
                    <p class="text">{{ params.version }}</p>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    name: 'GlueJobDetail',
    props: {
        params: Object,
    },
    setup() {},
};
</script>
