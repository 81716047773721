<template>
    <article class="content-section section-tabcontent">
        <!--        <div class="code-viewer">-->
        <!--            <div class="code-line">-->
        <!--                <template v-if="scriptRows > 0">-->
        <!--                    <span v-for="index in scriptRows" class="line" :key="index">{{ index + 1 }}</span>-->
        <!--                </template>-->
        <!--            </div>-->
        <!--            <div class="code-body">-->
        <!--                <pre>{{ params.runScript }}</pre>-->
        <!--            </div>-->
        <!--        </div>-->

        <div class="highlight-code">
            <pre class="language-python"><code ref="code" class="language-python">{{ scriptElement }}</code></pre>
        </div>
    </article>
</template>

<script>
import {computed, onMounted, ref} from 'vue';
import prism from 'prismjs';

export default {
    name: 'GlueJobScript',
    props: {
        params: Object,
    },
    setup(props) {
        const code = ref(null);

        // const scriptRows = computed(() => {
        //     if (props.params.runScript) {
        //         return props.params.runScript.split('\n').length;
        //     }
        //     return 0;
        // });

        const scriptElement = computed(() => {
            if (props.params.runScript) {
                // return prism.highlight(props.params.runScript, prism.languages.python, 'python');
                // return prism.highlightElement(props.params.runScript);
                return props.params.runScript;
            }
            return '';
        });

        onMounted(() => {
            if (props.params.runScript) {
                prism.highlightElement(code.value);
            }
        });

        return {
            code,
            // scriptRows,
            scriptElement,
        };
    },
};
</script>
