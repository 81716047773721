<template>
    <article class="content-section section-tabcontent section-margin">
        <header class="section-header header-divider d-flex">
            <div class="header-column">
                <h4 class="title title-sm">{{ jobName }} <span class="title-sub" /></h4>
                <!--                <p class="description">{{ timestampToDateFormat(item.startDt, 'yyyy.MM.dd hh:mm:ss') }}</p>-->
            </div>
            <div class="header-side side-icon-actions">
                <button class="btn-action" @click="reloadJobRunList"><i class="icon-refresh"></i></button>
            </div>
        </header>
        <div v-for="(item, index) in renderItems" class="content-text-container" :key="index">
            <!-- text-item -->
            <!--            <div class="text-item">-->
            <!--                <h4 class="item-title">Job Name</h4>-->
            <!--                <div class="item-content">-->
            <!--                    <a class="text text-blue">{{ item.jobName }}</a>-->
            <!--                </div>-->
            <!--            </div>-->
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Id</h4>
                <div class="item-content">
                    <a class="text text-blue">{{ item.id }}</a>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Run status</h4>
                <div class="item-content">
                    <p class="text text-icon" :class="{'text-primary': item.jobRunState === 'SUCCEEDED', 'text-red': item.jobRunState === 'FAILED'}">
                        <strong>{{ item.jobRunState }}</strong> <i v-if="item.jobRunState === 'SUCCEEDED'" class="icon-check-primary"></i>
                    </p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Glue version</h4>
                <div class="item-content">
                    <p class="text">{{ item.glueVersion }}</p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Retry attempt number</h4>
                <div class="item-content">
                    <p class="text">{{ item.attempt === 1 ? 'Initial run' : item.attempt }}</p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Start time</h4>
                <div class="item-content">
                    <p class="text">{{ timestampToDateFormat(item.startDt, 'yyyy.MM.dd hh:mm:ss') }}</p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">End time</h4>
                <div class="item-content">
                    <p class="text">{{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd hh:mm:ss') }}</p>
                </div>
            </div>
            <!-- text-item -->
            <!--            <div class="text-item">-->
            <!--                <h4 class="item-title">Start-up time</h4>-->
            <!--                <div class="item-content">-->
            <!--                    <p class="text">5 seconds</p>-->
            <!--                </div>-->
            <!--            </div>-->
            <!-- text-item -->
            <!--            <div class="text-item">-->
            <!--                <h4 class="item-title">Execution time</h4>-->
            <!--                <div class="item-content">-->
            <!--                    <p class="text">12 seconds</p>-->
            <!--                </div>-->
            <!--            </div>-->
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Last modified on</h4>
                <div class="item-content">
                    <p class="text">{{ timestampToDateFormat(item.lastUpdateDt, 'yyyy.MM.dd hh:mm:ss') }}</p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Trigger name</h4>
                <div class="item-content">
                    <p class="text">{{ item.triggerName ? item.triggerName : '-' }}</p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Security configuration</h4>
                <div class="item-content">
                    <p class="text">{{ item.securityConfiguration ? item.securityConfiguration : '-' }}</p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Timeout</h4>
                <div class="item-content">
                    <p class="text">{{ item.timeout }} minutes</p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Max capacity</h4>
                <div class="item-content">
                    <p class="text">{{ item.maxCapacity }} DPUs</p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Number of workers</h4>
                <div class="item-content">
                    <p class="text">{{ item.numberOfWorkers > 0 ? item.numberOfWorkers : '-' }}</p>
                </div>
            </div>
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Worker type</h4>
                <div class="item-content">
                    <p class="text">{{ item.workerType ? item.workerType : '-' }}</p>
                </div>
            </div>
            <!-- text-item -->
            <!--            <div class="text-item">-->
            <!--                <h4 class="item-title">Execution class</h4>-->
            <!--                <div class="item-content">-->
            <!--                    <p class="text">STANDARD</p>-->
            <!--                </div>-->
            <!--            </div>-->
            <!-- text-item -->
            <div class="text-item">
                <h4 class="item-title">Log group name</h4>
                <div class="item-content">
                    <p class="text">{{ item.logGroupName ? item.logGroupName : '-' }}</p>
                </div>
            </div>
        </div>

        <CommonPagination :paging="paging" :page-func="pageFunc" />
    </article>
</template>

<script>
import ApiService from '@/assets/js/api.service';
import {computed, reactive, ref} from 'vue';
import {getItems, lengthCheck, paginate, timestampToDateFormat} from '@/assets/js/common.utils';
import CommonPagination from '@/components/common/CommonPagination';

export default {
    name: 'GlueJobRun',
    components: {CommonPagination},
    props: {
        jobName: String,
        params: Object,
    },
    setup: function (props) {
        const items = ref([]);
        const paging = reactive({
            pageNo: 1,
            pageSize: 1,
            totalCount: computed(() => items.value.length),
        });

        const renderItems = computed(() => paginate(items.value, paging.pageNo, paging.pageSize));

        const getJobRunList = () => {
            ApiService.query(`/v1/aws/glue/jobs/${props.params.name}/runs`, {}).then(res => {
                if (lengthCheck(res)) {
                    items.value = getItems(res);
                }
            });
        };

        const pageFunc = pageNo => {
            paging.pageNo = pageNo;
            window.scrollTo(0, 0);
        };

        const reloadJobRunList = () => {
            paging.pageNo = 1;
            getJobRunList();
        };

        getJobRunList();

        return {
            renderItems,
            paging,
            pageFunc,
            reloadJobRunList,
            timestampToDateFormat,
        };
    },
};
</script>
